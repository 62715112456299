<template>
  <page-layout
    :title="$t('user_management.add_app_users_page.title')"
    :sub-title="$t('user_management.add_app_users_page.set_user_groups.subtitle')"
    :has-back-button="true"
    :emit-back="true"
    @back="$emit('back')"
    class="add-app-user-page"
  >
    <template #header-actions>
      <base-button
        :label="$t('user_management.add_app_users_page.create_user')"
        :loading="submitProcessing"
        type="primary"
        @click="handleSubmit"
      />
    </template>
    <el-alert type="info" :closable="false" show-icon>
      <span
        v-html="$t('user_management.add_app_users_page.set_user_groups.alert_text', {name: fullname})"
      />
    </el-alert>
    <base-section
      :title="$t('user_management.add_app_users_page.set_user_groups.user_groups_section_title')"
      :is-flex="true"
    >
      <base-transfer
        :top-titles="[$t('user_management.view_user_groups_page.members.transfer.left_panel_title'), $t('user_management.view_user_groups_page.members.transfer.right_panel_title')]"
        filterable
        :titles="[$t('general.label.select_all'), $t('general.label.select_all')]"
        :filter-method="filterMethod"
        v-model="selectedUserGroups"
        :data="transferData"
      ></base-transfer>
    </base-section>
    <p class="text-large text-bold">
      {{
      $t('user_management.add_app_users_page.set_user_groups.products_title', {
      fullname
      })
      }}
    </p>
    <service-resources-section :service-resources="serviceResources" v-loading="productsLoading"></service-resources-section>
    <base-section>
      <div class="action-button-container">
        <base-button
          :label="$t('user_management.add_app_users_page.create_user')"
          :loading="submitProcessing"
          type="primary"
          @click="handleSubmit"
        />
      </div>
    </base-section>
  </page-layout>
</template>

<script>
import PageLayout from '../../layouts/PageLayout'
import { mapActions, mapGetters } from 'vuex'
import ServiceResourceRepository from '@/app/repositories/ServiceResourceRepository'
import ServiceResourcesSection from '@/components/sections/ServiceResourcesSection'
import { uniq } from 'lodash'

const serviceResourceRepository = new ServiceResourceRepository()

export default {
  name: 'SetUserGroups',
  components: {
    PageLayout,
    ServiceResourcesSection
  },
  props: {
    submitProcessing: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      data: {},
      selectedUserGroups: [],
      serviceResources: [],
      productsLoading: false
    }
  },
  computed: {
    ...mapGetters({
      appUserForm: 'user/appUserForm',
      userGroups: 'userGroup/all',
      corporateAccount: 'corporateAccount/selectedCorporateAccount'
    }),
    fullname () {
      return `${this.appUserForm.personalForm.firstname} ${this.appUserForm.personalForm.lastname}`
    },
    transferData () {
      return this.userGroups
        .filter((v) => {
          return v.data.active && !v.isDefault(this.corporateAccount.extId)
        })
        .map((v) => {
          return {
            key: v.ospId,
            label: v.name,
            disabled: !v.isActive
          }
        })
    },
    defaultServiceResource () {
      return this.userGroups.find((v) =>
        v.isDefault(this.corporateAccount.extId)
      )
    }
  },
  watch: {
    selectedUserGroups (newValue) {
      const userGroups = [
        ...newValue.slice(),
        this.defaultServiceResource.ospId
      ]
      this.appUserForm.userGroups = userGroups
      this.setAppUserForm(this.appUserForm)
      this.productsLoading = true
      serviceResourceRepository.getByUserGroups(uniq(userGroups), {
        onSuccess: (response) => {
          this.setServiceResources(response)
        },
        onFinally: () => {
          this.productsLoading = false
        }
      })
    }
  },
  methods: {
    ...mapActions({
      setAppUserForm: 'user/setAppUserForm'
    }),
    handleSubmit () {
      this.$emit('submit')
    },
    filterMethod (query, item) {
      return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
    },
    setServiceResources (resources) {
      this.serviceResources = resources
    }
  },
  mounted () {
    this.selectedUserGroups = this.appUserForm.userGroups.slice()
  }
}
</script>

<style lang="scss">
</style>
